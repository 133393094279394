.containerRicerca {

    width: auto;
    color: blue;
    border: 0.5px black solid;
    border-radius: 0.2rem;

}

.containerRicerca > ul {
    list-style: none;
    margin-left: 10px;
    padding: 0px;
}

.containerRicerca2 {

    width: auto;
    color: blue;

}

.containerRicerca2 > ul {
    list-style: none;
    margin-left: 10px;
    padding: 0px;
}
