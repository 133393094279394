.break {
  width: 700px;
  word-wrap: break-word;
  display: inline-block;
}

.tableContainer {
  width: 100%;
  height: 500px;
  overflow: scroll;
  scroll-behavior: auto;
}

.table>thead {
    text-align: center
}
td{
    vertical-align: middle;
}
