  .tableContainerEventi {
    width: 100%;
    height: 800px;
    overflow: scroll;
    scroll-behavior: auto;
  }
  
  .table>thead {
      text-align: center
  }
  td{
      vertical-align: middle;
  }
  
  .refreshIcon {
    cursor: pointer;
  }

  .refreshIcon:hover {
    background-color: rgb(232, 240, 243);

  }
  