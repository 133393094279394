.msg-container {
    text-align: center;

}

.err-msg {
    background-color: rgb(253, 85, 43);
    color: white;
    display: inline-block;
    margin-top: 30px;
    text-align: center;
    padding: 8px;
    font-size: 24px;
    font-weight: bold;
    border-radius: 10px;
    border: 1px rgb(182, 177, 177) solid;
    box-shadow: 2px 2px 0.5px 0.5px rgb(65, 56, 56);
}

.form-container {
    width: 100%;
    text-align: center;

}

.form {
    margin: 20px;
}

.form-input {
    display: block;
    margin: 8px auto;
}

.loading-img {
    width: 200px;
}